import { useEffect} from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

function ThankYou() {
  const [, setVisitedCodes] = useLocalStorage('visitedCodes', []);

  useEffect(() => {
    setVisitedCodes([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    <h1>It's all good man. Ask our man for some goodies!</h1>

    
    <img className="mt-12" src="https://upload.wikimedia.org/wikipedia/en/thumb/8/8a/Better_Call_Saul_logo.svg/500px-Better_Call_Saul_logo.svg.png" alt="" />
  </>
}

export default ThankYou;