import { useState, useEffect, useRef, useCallback, } from 'react';
import { Redirect, useHistory, useParams, } from 'react-router-dom';
import useLocalStorage from '../hooks/useLocalStorage';
import useScanner from '../hooks/useScanner';


function useCode() {
  const [code, setCode] = useState();
  const [prevVisitedCodes, setPrevVisitedCodes] = useState([]);
  const [visitedCodes, saveVisitedCodes] = useLocalStorage('visitedCodes', []);
  const [random, setRandom] = useState(Math.random());

  const isCodeVisited = useCallback((code) => {
    return prevVisitedCodes.includes(code);
  }, [prevVisitedCodes]);

  const rerender = useCallback(() => {
    if (!code) {
      return;
    }

    saveVisitedCodes((storedCodes) => {
      setPrevVisitedCodes(storedCodes);
      return Array.from(new Set([...storedCodes, code]));
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [random, code]);

  // Gotta use some other value to trigger rerender because if user scans visited code,
  // state isn't going to be updated
  useEffect(() => {
    rerender();
  }, [random, rerender]);

  return [visitedCodes, isCodeVisited, (code) => {
    setCode(code);
    setRandom(Math.random());
  }];
}

function Entry({
  codes
}) {
  const { code } = useParams();
  const history = useHistory();
  const scannerVideoRef = useRef(null);
  const [visitedCodes, isCodeVisited, setCode] = useCode(code);

  const {
    start: startScan,
    isScanning,
    scannedValue,
  } = useScanner(scannerVideoRef);

  useEffect(() => {
    setCode(code);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const scannedUrl = scannedValue.data;
    if (scannedUrl) {
      console.debug('Scanned URL: %s', scannedUrl);

      const {
        pathname,
        origin,
       } = new URL(scannedUrl);

       if (process.env.NODE_ENV === 'production' && process.env.PUBLIC_URL !== origin) {
        console.warn('Scanned URL is not from the same origin as the app');
        return;
       }

      history.replace(pathname);

      const newCode = pathname.split('/').pop();

      console.log('Setting new code', newCode);

      setCode(newCode);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannedValue, history]);

  if (visitedCodes.length >= codes.length) {
    return <Redirect to="/saul-good-man" />;
  }

  if (!codes.includes(code)) {
    return <Redirect to="/" />
  }

  return <div className="entry">
    <p>
      Scanned code: <strong>{code}</strong>
    </p>

    {isCodeVisited(code)
      && <p className="text-red-500">Already scanned this code. Scan another one!</p>
    }

    <h1 className="mb-4 text-center">
      {visitedCodes.length} / {codes.length}
    </h1>

    {!isScanning
      && <p><button onClick={startScan}>Scan next code</button></p>
    }
    
    <video ref={scannerVideoRef} className={`scanner ${isScanning ? 'scanning' : ''}`}></video>

    <p className="absolute bottom-4">
      <small>Already visited these: {visitedCodes.length ? visitedCodes.join(', ') : 'none'}</small>
    </p>

  </div>
}

export default Entry;