import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Homepage from './components/Homepage';
import Entry from './components/Entry';
import SaulGoodman from './components/ThankYou';
import './App.css';

const gameCodes = process.env.REACT_APP_CODES.split(',');

function App() {
  return (
    <div className="app">
      <Router>
        <Switch>
          <Route path="/" exact>
            <Homepage />
          </Route>
          <Route path="/c/:code">
            <Entry codes={gameCodes} />
          </Route>
          <Route path="/saul-good-man" exact>
            <SaulGoodman />
          </Route>
          <Route path="*"  exact>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
