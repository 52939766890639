import { useState, useCallback, useEffect } from 'react';
import QrScanner from 'qr-scanner';

function useScanner(videoRef) {
  const [scannerInstance, setScannerInstance] = useState(null);
  const [isScanning, setIsScanning] = useState(false);
  const [scannedValue, setScannedValue] = useState({ data: null });

  useEffect(() => {
    console.debug('Scanner: New videoRef, creating new QRScanner instance');

    if (videoRef.current) {
      const scanner = new QrScanner(
        videoRef.current,
        (result) => {
          if (result?.data) {
            console.debug('Scanner: Scanned QR code: %s', result.data);
            setScannedValue({ data: result.data, random: Math.random(), });
            
            scanner.stop();
            setIsScanning(false);
          }
        },
        {
          maxScansPerSecond: 10,
          highlightScanRegion: true,
          highlightCodeOutline: true,
        }
      );

      setScannerInstance(scanner);

      return () => scanner.destroy();
    }
  }, [videoRef]);

  const start = useCallback(() => {
    console.debug('Scanner: Start');
    scannerInstance.start();
    setIsScanning(true);
  }, [scannerInstance]);

  const stop = useCallback(() => {
    console.debug('Scanner: Stop');
    if (scannerInstance) {
      scannerInstance.stop();
      setIsScanning(false);
    }
  }, [scannerInstance]);

  return {
    start,
    stop,
    isScanning,
    scannedValue,
  }
}

export default useScanner;
